/**********************************************
 * Import all bones css modules
*/

// @import '~@bones/core/assets/css/bones-table.scss';
@import 'bones-table.scss';

[text-pre-line]
{
    white-space: pre-line;
}
