/********************************
 * Tables
 */

.bones-table
{
    border: 1px solid var(--ion-color-dark);
    border-collapse: collapse;
    color: var(--ion-color-dark);
    background-color: var(--ion-color-light);

    &.full-width
    {
        width: 100%;
    }

    caption
    {
        background-color: var(--ion-color-secondary);
        color: var(--ion-color-light);
        padding: .25em;
        font-size: 1.2em;
        font-weight: bold;
    }

    tbody > tr:nth-child(odd)
    {
        background-color: var(--ion-color-light-shade);
    }
    thead > tr > th
    {
        color: var(--ion-color-tertiary);
        border-bottom: 1px solid;
    }
    thead > tr > th,
    tbody > tr > td
    {
        padding: .1em .25em;
        border-right: 1px solid var(--ion-color-medium);
    }
    thead > tr > th:last-child,
    tbody > tr > td:last-child
    {
        border-right: none;
    }
    tbody > tr > th
    {
        background-color: var(--ion-color-light);
        color: var(--ion-color-tertiary);
        text-align: left;
        padding: .2em .5em;
        border-right: 1px solid var(--ion-color-medium);
        border-bottom: 1px solid var(--ion-color-medium);
        border-top: 1px solid var(--ion-color-medium);
    }
}

/********************************
 * Tables that look like cards
 */

.bones-card-table
{
    border-collapse: collapse;
    margin: 10px;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    background-color: var(--ion-color-light);

    &.full-width
    {
        width: calc(100% - 20px);
    }

    caption
    {
        color: var(--ion-color-secondary);
        padding: 14px;
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        background-color: var(--ion-color-light);
        border-radius: 4px 4px 0 0;
    }

    thead
    {
        border-bottom: 1px solid var(--ion-color-secondary);
    }
    thead > tr > th
    {
        color: var(--ion-color-secondary);
        padding: .25em .5em;
       /*  border-bottom: 1px solid; */
    }

    tbody > tr:nth-child(even)
    {
        background-color: var(--ion-color-light-shade);
    }
    tbody > tr > td
    {
        padding: .1em .25em;
        border-right: 1px solid var(--ion-color-medium);
    }
    tbody > tr > td:last-child
    {
        border-right: none;
    }
    tbody > tr > th
    {
        background-color: var(--ion-color-light);
        color: var(--ion-color-secondary);
        text-align: left;
        padding: .2em .5em;
        border-right: 1px solid var(--ion-color-medium);
        border-bottom: 1px solid var(--ion-color-medium);
        border-top: 1px solid var(--ion-color-medium);
    }
}
