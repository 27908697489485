.bones-edit-form-90-80::part(content)
{
    --width: 90%;
    --height: 80%;
}
.bones-edit-form-100::part(content)
{
    --width: 100%;
    --height: 100%;
}
.bones-edit-form-wide::part(content)
{
    --width: 90%;
}
.bones-edit-form-tall::part(content)
{
    --height: 80%;
}
